'use client'

/*
| Developed by Muzisecur
| Filename: EFETextField.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { FastField, Field } from 'formik'
import { TextField } from 'formik-mui'
import { ExelysFormFieldProps } from '@dirupt/exelys-form'
import { IconButton } from '@mui/material'
import { EFEFieldLayout } from '@/components/common/exelys-form-extended/EFEFieldLayout'
import { EyeIcon } from '@/components/common/icons/EyeIcon'
import { EyeSlashIcon } from '@/components/common/icons/EyeSlashIcon/EyeSlashIcon'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EFETextField: React.FC<ExelysFormFieldProps<'customTextField'>> = ({
	label,
	description,
	name,
	optional,
	fieldType: _fieldType,
	defaultInitialValue: _defaultInitialValue,
	renderLayout: _renderLayout,
	password,
	...props
}) => {
	const [showPassword, setShowPassword] = React.useState(false)

	// Render
	//--------------------------------------------------------------------------
	return (
		<EFEFieldLayout label={label} description={description} optional={optional}>
			{password ? (
				<Field
					name={name}
					component={TextField}
					{...props}
					InputProps={{
						endAdornment: (
							<IconButton onClick={() => setShowPassword((value) => !value)}>
								{!showPassword ? <EyeIcon /> : <EyeSlashIcon />}
							</IconButton>
						),
					}}
					type={showPassword ? 'text' : 'password'}
				/>
			) : (
				<FastField name={name} component={TextField} {...props} />
			)}
		</EFEFieldLayout>
	)
}

/*
| Developed by Muzisecur
| Filename: EFESelectField.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { ExelysFormFieldProps } from '@dirupt/exelys-form'
import { Field } from 'formik'
import { Select } from 'formik-mui'
import { EFEFieldLayout } from '@/components/common/exelys-form-extended/EFEFieldLayout'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EFESelectField: React.FC<ExelysFormFieldProps<'selectField'>> = ({
	name,
	children,
	label,
	description,
	optional,
	fieldType: _fieldType,
	defaultInitialValue: _defaultInitialValue,
	renderLayout: _renderLayout,
	...props
}) => {
	// Render
	//--------------------------------------------------------------------------
	return (
		<EFEFieldLayout label={label} description={description} optional={optional}>
			<Field name={name} component={Select} fullWidth {...props}>
				{children}
			</Field>
		</EFEFieldLayout>
	)
}

/*
| Developed by Muzisecur
| Filename: EFEDateTimeField.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { useField, useFormikContext } from 'formik'
import { DateTimePicker } from '@mui/x-date-pickers'
import { ExelysFormFieldProps } from '@dirupt/exelys-form'
import { EFEFieldLayout } from '@/components/common/exelys-form-extended/EFEFieldLayout'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EFEDateTimeField: React.FC<ExelysFormFieldProps<'dateTimeField'>> = ({
	name,
	label,
	description,
	optional,
	fieldType: _fieldType,
	defaultInitialValue: _defaultInitialValue,
	renderLayout: _renderLayout,
	...props
}) => {
	const { isSubmitting } = useFormikContext()
	const [{ value }, { touched, error }, { setValue, setTouched }] = useField(name ?? '')

	// Render
	//--------------------------------------------------------------------------
	return (
		<EFEFieldLayout label={label} description={description} optional={optional}>
			<DateTimePicker
				{...props}
				sx={{ width: '100%', ...props.sx }}
				value={value}
				onChange={async (value) => {
					await setTouched(true)
					await setValue(value, true)
				}}
				formatDensity="spacious"
				disabled={isSubmitting}
				slotProps={{
					textField: {
						inputProps: {
							'data-testid': 'input-date',
						},
						helperText: touched ? error : '',
						error: touched ? Boolean(error) : false,
					},
				}}
			/>
		</EFEFieldLayout>
	)
}

/*
| Developed by Muzisecur
| Filename: EFETimeField.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { ExelysFormFieldProps } from '@dirupt/exelys-form'
import { useField, useFormikContext } from 'formik'
import { TimePicker } from '@mui/x-date-pickers'
import { EFEFieldLayout } from '@/components/common/exelys-form-extended/EFEFieldLayout'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EFETimeField: React.FC<ExelysFormFieldProps<'timeField'>> = ({
	name,
	label,
	description,
	optional,
	...props
}) => {
	const { isSubmitting } = useFormikContext<{ [name: string]: string }>()
	const [, { error, touched, value }, { setValue, setTouched }] = useField(name ?? '')

	// Render
	//--------------------------------------------------------------------------
	return (
		<EFEFieldLayout label={label} description={description} optional={optional}>
			<TimePicker
				sx={{ width: '100%' }}
				value={value}
				onChange={async (value) => {
					await setValue(value, true)
					await setTouched(true)
				}}
				formatDensity="spacious"
				disabled={isSubmitting}
				slotProps={{
					textField: {
						inputProps: {
							'data-testid': 'input-time',
						},
						error: touched && error ? Boolean(error) : false,
						helperText: touched && error ? error : '',
					},
				}}
				ampm={false}
				{...props}
			/>
		</EFEFieldLayout>
	)
}

/*
| Developed by Muzisecur
| Filename : api.interceptors.ts
| Author : Calixte DE TOURRIS (calixte@muzisecur.fr)
*/

import { AxiosError, AxiosResponse } from 'axios'
import { NextRouter } from 'next/router'
import { UrlConfig } from '@/configs/url.config'

/*
|--------------------------------------------------------------------------
| Response interceptor
|--------------------------------------------------------------------------
*/
export const commonResponseInterceptorSuccess = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
	return response
}

export const commonResponseInterceptorError =
	(router?: NextRouter) =>
	(error: AxiosError): any => {
		console.log(error)

		// Sentry
		//--------------------------------------------------------------------------
		// eslint-disable-next-line import/namespace
		if (error?.response) {
			console.log(error.response.status)
			if (error.response.status === 401) {
				if (router) {
					void router.push(UrlConfig.auth.login.url)
					return
				} else if (window && window?.location?.href) {
					window.location.href = UrlConfig.auth.login.url
					return
				} else {
					console.log('[Muzisecur Auth] Session expired.')
				}
			} else if (error.response.status === 403) {
				// Empty catch
			} else if (error?.response?.status === 404 || error?.code === 'ERR_NETWORK') {
				// Empty catch
			} else if (error?.response?.status === 500) {
				// Empty catch
			} else if (error?.response?.status === 522) {
				// Empty catch
			}

			// Otherwise we reject error
			else {
				return Promise.reject(error)
			}
		} else if (error?.request) {
			console.error('[Muzisecur API] Request problem.', error.request)
		} else {
			// SentryService.captureExceptionWithTags(error, SENTRY_TAG_SECTION)
		}

		console.log('rejecting the error')
		return Promise.reject(error)
	}

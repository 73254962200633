/*
| Developed by Muzisecur
| Filename: EFETimeField.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { useField, useFormikContext } from 'formik'
import { DatePicker, StaticDatePicker } from '@mui/x-date-pickers'
import { ExelysFormFieldProps } from '@dirupt/exelys-form'
import { EFEFieldLayout } from '@/components/common/exelys-form-extended/EFEFieldLayout'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EFEDateField: React.FC<ExelysFormFieldProps<'dateField'>> = ({
	name,
	label,
	description,
	optional,
	fieldType: _fieldType,
	defaultInitialValue: _defaultInitialValue,
	renderLayout: _renderLayout,
	staticPicker,
	...props
}) => {
	const { isSubmitting } = useFormikContext()
	const [{ value }, { touched, error }, { setValue, setTouched }] = useField(name ?? '')

	// Render
	//--------------------------------------------------------------------------
	return (
		<EFEFieldLayout label={label} description={description} optional={optional}>
			{staticPicker ? (
				<StaticDatePicker
					{...props}
					sx={{ width: '100%', ...props.sx }}
					value={value}
					onChange={async (value) => {
						await setTouched(true)
						await setValue(value, true)
					}}
					disabled={isSubmitting}
					slotProps={{
						actionBar: { actions: [] },
					}}
				/>
			) : (
				<DatePicker
					{...props}
					sx={{ width: '100%', ...props.sx }}
					value={value}
					onChange={async (value) => {
						await setTouched(true)
						await setValue(value, true)
					}}
					formatDensity="spacious"
					disabled={isSubmitting}
					slotProps={{
						textField: {
							inputProps: {
								'data-testid': 'input-date',
							},
							helperText: touched ? error : '',
							error: touched ? Boolean(error) : false,
						},
					}}
				/>
			)}
		</EFEFieldLayout>
	)
}

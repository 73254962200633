/*
| Developed by Fleet
| Filename: EFEStudentAutoComplete.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Field, useField, useFormikContext } from 'formik'
import { ListItemButton, ListItemText } from '@mui/material'
import { useDebounceValue } from 'usehooks-ts'
import TextField from '@mui/material/TextField'
import { Autocomplete } from 'formik-mui'
import { ExelysFormFieldProps } from '@dirupt/exelys-form'
import { Student } from '@/services/api/students/students.contracts'
import { EFEFieldLayout } from '@/components/common/exelys-form-extended/EFEFieldLayout'
import { useI18n } from '@/locales/client'
import { useStudentsFindAll } from '@/services/api/students/students.endpoints'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EFEStudentAutoComplete: React.FC<ExelysFormFieldProps<'studentAutoComplete'>> = ({
	name,
	options,
	optional,
	fieldType: _fieldType,
	defaultInitialValue: _defaultInitialValue,
	renderLayout: _renderLayout,
	...props
}) => {
	const t = useI18n()
	const { touched } = useFormikContext<Record<string, unknown>>()
	const [{ value: optionValue }, { touched: fieldIsTouched, error }] = useField(name ?? '')

	// States
	//--------------------------------------------------------------------------
	const [value, setValue] = React.useState<string>('')
	const [q] = useDebounceValue(value, 500)

	// SWR
	//--------------------------------------------------------------------------
	const { data } = useStudentsFindAll({ q: optionValue ? '' : q, ...options })

	const fieldOptions = React.useMemo(() => {
		if (optionValue) {
			return [optionValue, ...(data?.data.filter((x) => x.id !== optionValue.id) ?? [])]
		}
		return data?.data ?? []
	}, [data?.data, optionValue])

	// Render
	//--------------------------------------------------------------------------
	return (
		<EFEFieldLayout label={t('common.inputs.student.label')} optional={optional}>
			<Field
				component={Autocomplete}
				name={name}
				// label={t('common:audioWorkAutoComplete.label')}
				options={fieldOptions}
				getOptionLabel={(option: Student) => option?.fullName}
				getOptionKey={(option: Student) => option.id}
				filterOptions={(x: Student) => x}
				onInputChange={(_event: any, value: string) => {
					setValue(value)
					// autoCompleteProps?.onInputChange?.(_event, value, reason)
				}}
				isOptionEqualToValue={(option: Student, value: Student) => option.id === value?.id}
				renderOption={(props: any, option: Student) => (
					<ListItemButton {...props} key={option.id}>
						<ListItemText primary={option?.fullName} />
					</ListItemButton>
				)}
				renderInput={(params: { id: string }) => {
					const isTouched = touched?.[params.id] || fieldIsTouched

					return (
						<TextField
							{...params}
							placeholder={t('common.inputs.student.placeholder')}
							error={isTouched ? !!error : false}
							helperText={isTouched && error ? error : ''}
						/>
					)
				}}
				{...props}
			/>
		</EFEFieldLayout>
	)
}

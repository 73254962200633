import useSWR from 'swr'
import { CrudService } from '@/services/api/crud-service'
import { CreateTeacherDto, Teacher, TeachersFindAllParams } from '@/services/api/teachers/teachers.contracts'

export const teachersEndpoints = new CrudService<Teacher, CreateTeacherDto>('/teachers')

// GET /teachers
//--------------------------------------------------------------------------
export const useTeachersFindAll = (params?: TeachersFindAllParams) => {
	const { url, fetcher } = teachersEndpoints.prepareFindAllHook(params)
	return useSWR(url, fetcher)
}

// GET /teachers/:id
//--------------------------------------------------------------------------
export const useTeachersFindOne = (id: string | null) => {
	const { url, fetcher } = teachersEndpoints.prepareFindOneHook(id)
	return useSWR(url, fetcher)
}

// GET /teachers/:id/pay
//--------------------------------------------------------------------------
export const teachersPay = async (id: string, paymentDate: string) => {
	return teachersEndpoints.client.post(`/teachers/${id}/pay`, { paymentDate })
}

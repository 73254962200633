import useSWR from 'swr'
import { CrudService } from '@/services/api/crud-service'
import { TeachersFindAllParams } from '@/services/api/teachers/teachers.contracts'
import { CreateStudentDto, Student } from '@/services/api/students/students.contracts'

export const studentsEndpoints = new CrudService<Student, CreateStudentDto>('/students')

// GET /students
//--------------------------------------------------------------------------
export const useStudentsFindAll = (params?: TeachersFindAllParams) => {
	const { url, fetcher } = studentsEndpoints.prepareFindAllHook(params)
	return useSWR(url, fetcher)
}

// GET /students/:id
//--------------------------------------------------------------------------
export const useStudentsFindOne = (id: string | null) => {
	const { url, fetcher } = studentsEndpoints.prepareFindOneHook(id)
	return useSWR(url, fetcher)
}

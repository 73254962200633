/*
| Developed by Muzisecur
| Filename: FieldErrorMessage.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Typography, TypographyProps } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface FieldErrorMessageProps {
	error: string | object | undefined
	typographyProps?: TypographyProps
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const FieldErrorMessage: React.FC<FieldErrorMessageProps> = ({ error, typographyProps }) => {
	// Render
	//--------------------------------------------------------------------------
	if (!error) return null
	if (typeof error === 'string') {
		return (
			<Typography variant="caption" color="error" {...typographyProps}>
				{error}
			</Typography>
		)
	}
	return Object.values(error).map((value, index) => (
		<FieldErrorMessage error={value} typographyProps={typographyProps} key={index} />
	))
}

'use client'
import { Montserrat, Roboto } from 'next/font/google'
import { createTheme } from '@mui/material/styles'
import { TransitionProps } from '@mui/material/transitions'
import { Slide } from '@mui/material'
import React from 'react'

/*
|--------------------------------------------------------------------------
| Components
|--------------------------------------------------------------------------
*/
const TransitionComponent = React.forwardRef(
	(
		props: TransitionProps & {
			children: React.ReactElement
		},
		ref: React.Ref<unknown>,
	) => {
		return (
			<Slide direction={'up'} ref={ref} {...props}>
				{props.children}
			</Slide>
		)
	},
)
TransitionComponent.displayName = 'TransitionComponent'

/*
|--------------------------------------------------------------------------
| Theme
|--------------------------------------------------------------------------
*/
const roboto = Roboto({
	weight: ['300', '400', '500', '700'],
	subsets: ['latin'],
	display: 'swap',
})

const logo = Montserrat({
	weight: ['400', '500', '600', '700'],
	subsets: ['latin'],
	display: 'swap',
})

const theme = createTheme({
	palette: {
		primary: {
			main: '#000',
		},
		success: {
			light: '#22C272',
			main: '#22C272',
			dark: '#22C272',
		},
		error: {
			main: '#ff0000',
		},
		warning: {
			main: '#E8B130',
		},
	},
	typography: {
		fontFamily: roboto.style.fontFamily,
		h1: {
			fontFamily: logo.style.fontFamily,
			fontSize: 24,
			fontWeight: 700,
			fontStyle: 'italic',
		},
	},
	components: {
		MuiDialog: {
			defaultProps: {
				TransitionComponent: TransitionComponent,
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&.MuiTableRow-hover': {
						cursor: 'pointer',
					},
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: 32,
					color: 'inherit',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					boxShadow: 'none',
					borderRadius: 2,
					// padding: '12px 24px',
					// fontSize: 16,
					'&:hover': {
						boxShadow: 'none',
					},
				},
			},
		},
	},
})

export default theme

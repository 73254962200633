/*
| Developed by Muzisecur
| Filename: EFEFieldLayout.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Box, Typography } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface EFEFieldLayoutProps {
	label: string
	description?: string
	optional?: boolean
	children: React.ReactNode
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EFEFieldLayout: React.FC<EFEFieldLayoutProps> = ({ label, description, optional, children }) => {
	// Render
	//--------------------------------------------------------------------------
	return (
		<Box display="flex" flexDirection="column" gap={0.5}>
			<Box display="flex" flexDirection="column" gap={0}>
				<Typography variant="subtitle2">
					{label}{' '}
					{optional ? (
						<Typography component="span" color="primary.main" variant="subtitle2">
							(facultatif)
						</Typography>
					) : null}
				</Typography>

				{description ? (
					<Typography variant="body2" color="text.secondary">
						{description}
					</Typography>
				) : null}
			</Box>
			{children}
		</Box>
	)
}

/*
| Developed by Muzisecur
| Filename: EFECheckboxGroupField.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Field, useField } from 'formik'
import { ExelysFormFieldProps } from '@dirupt/exelys-form'
import { CheckboxWithLabel, RadioGroup } from 'formik-mui'
import { EFEFieldLayout } from '@/components/common/exelys-form-extended/EFEFieldLayout'
import { FieldErrorMessage } from '@/components/common/form-helpers/FieldErrorMessage'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EFECheckboxGroupField: React.FC<ExelysFormFieldProps<'checkboxGroupField'>> = ({
	name,
	label,
	description,
	options,
	optional,
	fieldType: _fieldType,
	defaultInitialValue: _defaultInitialValue,
	renderLayout: _renderLayout,
	...props
}) => {
	const [, { error, touched }] = useField(name ?? '')

	// Render
	//--------------------------------------------------------------------------
	return (
		<EFEFieldLayout label={label} description={description} optional={optional}>
			{touched && error ? <FieldErrorMessage error={error} /> : null}
			<Field
				component={RadioGroup}
				name={name}
				sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}
				{...props}
			>
				{options?.map(({ label, ...optionProps }, index) => (
					<Field
						key={index}
						component={CheckboxWithLabel}
						type="checkbox"
						name={name}
						Label={{ label }}
						{...optionProps}
					/>
					// <FormControlLabel
					// 	control={<Checkbox />}
					// 	{...option}
					// 	key={index}
					// 	{...(description && {
					// 		disableTypography: true,
					// 		sx: {
					// 			display: 'flex',
					// 			alignItems: 'flex-start',
					// 		},
					// 		label: (
					// 			<Box>
					// 				<Typography variant="subtitle2">{option.label}</Typography>
					// 				<Typography variant="body2" color="text.secondary">
					// 					{description}
					// 				</Typography>
					// 			</Box>
					// 		),
					// 	})}
					// />
				))}
			</Field>
		</EFEFieldLayout>
	)
}

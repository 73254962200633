/*
| Developed by Muzisecur
| Filename: EFETextField.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { ExelysFormFieldProps } from '@dirupt/exelys-form'
import { Field } from 'formik'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { TextField } from 'formik-mui'
import { EFEFieldLayout } from '@/components/common/exelys-form-extended/EFEFieldLayout'

/*
|--------------------------------------------------------------------------
| Sub component
|--------------------------------------------------------------------------
*/
export const NumericSubComponent = React.forwardRef<NumericFormatProps, NumericFormatProps>(
	function NumericFormatCustom(props, ref) {
		const { onChange, ...other } = props

		return (
			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange?.({
						target: {
							name: props.name as string,
							// @ts-ignore
							value: typeof values.floatValue === 'number' ? values.floatValue : null,
						},
					})
				}}
				valueIsNumericString
			/>
		)
	},
)

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EFENumberField: React.FC<ExelysFormFieldProps<'numberField'>> = ({
	label,
	description,
	name,
	numericFormatProps,
	optional,
	fieldType: _fieldType,
	defaultInitialValue: _defaultInitialValue,
	renderLayout: _renderLayout,
	...props
}) => {
	// Render
	//--------------------------------------------------------------------------
	return (
		<EFEFieldLayout label={label} description={description} optional={optional}>
			<Field
				name={name}
				fullWidth
				component={TextField}
				{...props}
				inputProps={{
					...numericFormatProps,
					...props.inputProps,
				}}
				InputProps={{
					inputComponent: NumericSubComponent as any,
					...props.InputProps,
				}}
			/>
		</EFEFieldLayout>
	)
}

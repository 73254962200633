/*
| Developed by Muzisecur
| Filename: EFERadioGroupField.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Field, useField } from 'formik'
import { Box, FormControlLabel, Radio, Typography } from '@mui/material'
import { ExelysFormFieldProps } from '@dirupt/exelys-form'
import { RadioGroup } from 'formik-mui'
import { EFEFieldLayout } from '@/components/common/exelys-form-extended/EFEFieldLayout'
import { FieldErrorMessage } from '@/components/common/form-helpers/FieldErrorMessage'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EFERadioGroupField: React.FC<ExelysFormFieldProps<'customRadioGroupField'>> = ({
	name,
	label,
	description,
	options,
	optional,
	fieldType: _fieldType,
	defaultInitialValue: _defaultInitialValue,
	renderLayout: _renderLayout,
	...props
}) => {
	const [, { error }] = useField(name ?? '')

	// Render
	//--------------------------------------------------------------------------
	return (
		<EFEFieldLayout label={label} description={description} optional={optional}>
			<FieldErrorMessage error={error} />
			<Field component={RadioGroup} name={name} sx={{ display: 'flex', flexDirection: 'row', gap: 1 }} {...props}>
				{options?.map(({ description, ...option }, index) => (
					<FormControlLabel
						control={<Radio />}
						{...option}
						key={index}
						{...(description && {
							disableTypography: true,
							sx: {
								display: 'flex',
								alignItems: 'flex-start',
							},
							label: (
								<Box>
									<Typography variant="subtitle2">{option.label}</Typography>
									<Typography variant="body2" color="text.secondary">
										{description}
									</Typography>
								</Box>
							),
						})}
					/>
				))}
			</Field>
		</EFEFieldLayout>
	)
}

'use client'

import { Inter } from 'next/font/google'
import { AppRouterCacheProvider } from '@mui/material-nextjs/v13-appRouter'
import { Grid, ThemeProvider } from '@mui/material'
import { ExelysFormConfig } from '@dirupt/exelys-form'
import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Toaster } from 'sonner'
import { frFR } from '@mui/x-date-pickers/locales'
import { createTheme } from '@mui/material/styles'
import { I18nProviderClient } from '@/locales/client'
import theme from '@/styles/theme'
import { EFETextField } from '@/components/common/exelys-form-extended/EFETextField'
import '../../styles/global.css'
import { axiosInstance } from '@/services/api/axios-instance'
import { commonResponseInterceptorError, commonResponseInterceptorSuccess } from '@/services/api/Interceptors'
import { EFESelectField } from '@/components/common/exelys-form-extended/EFESelectField'
import { EFETeacherAutoComplete } from '@/components/common/exelys-form-extended/EFETeacherAutoComplete'
import { EFEStudentAutoComplete } from '@/components/common/exelys-form-extended/EFEStudentAutoComplete'
import { EFEDateField } from '@/components/common/exelys-form-extended/EFEDateField'
import { EFENumberField } from '@/components/common/exelys-form-extended/EFENumberField'
import { EFERadioGroupField } from '@/components/common/exelys-form-extended/EFERadioGroupField'
import { EFETimeField } from '@/components/common/exelys-form-extended/EFETimeField'
import { EFEDateTimeField } from '@/components/common/exelys-form-extended/EFEDateTimeField'
import { EFEAutoCompleteField } from '@/components/common/exelys-form-extended/EFEAutoCompleteField'
import { EFECheckboxGroupField } from '@/components/common/exelys-form-extended/EFECheckboxGroupField/EFECheckboxGroupField'

const inter = Inter({ subsets: ['latin'] })

// export const metadata: Metadata = {
// 	title: 'Create Next App',
// 	description: 'Generated by create next app',
// }

export default function RootLayout({
	children,
	params: { locale },
}: Readonly<{
	children: React.ReactNode
	params: { locale: string }
}>) {
	React.useEffect(() => {
		axiosInstance.interceptors.response.use(commonResponseInterceptorSuccess, commonResponseInterceptorError())
		return () => axiosInstance.interceptors.response.clear()
	}, [])

	const localeTheme = React.useMemo(() => {
		return createTheme(theme, frFR)
	}, [])

	return (
		<html lang="en">
			<AppRouterCacheProvider>
				<ThemeProvider theme={localeTheme}>
					<I18nProviderClient locale={locale}>
						<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
							<body className={inter.className}>
								<Toaster position="bottom-center" />
								<ExelysFormConfig
									formContainerProps={{
										display: 'flex',
										flexDirection: 'column',
										gap: 3,
										marginTop: 3,
										// marginBottom: 15,
										paddingBottom: 3,
									}}
									propertiesFactoryRenderLayout={({ children }) => (
										<Grid container rowSpacing={2} columnSpacing={1}>
											{children}
										</Grid>
									)}
									footerProps={{
										submitProps: {
											sx: {
												marginLeft: 'auto',
											},
											// endIcon: <ArrowForwardOutlined />,
											// children: t('common:common.next'),
										},
									}}
									fields={{
										numberField: EFENumberField,
										customTextField: EFETextField,
										autoCompleteField: EFEAutoCompleteField,
										dateField: EFEDateField,
										customRadioGroupField: EFERadioGroupField,
										timeField: EFETimeField,
										dateTimeField: EFEDateTimeField,
										// formPickerField: EFEFormPickerField,
										selectField: EFESelectField,
										// fileField: EFEFileField,
										teacherAutoComplete: EFETeacherAutoComplete,
										studentAutoComplete: EFEStudentAutoComplete,
										checkboxGroupField: EFECheckboxGroupField,
									}}
								>
									{children}
								</ExelysFormConfig>
							</body>
						</LocalizationProvider>
					</I18nProviderClient>
				</ThemeProvider>
			</AppRouterCacheProvider>
		</html>
	)
}

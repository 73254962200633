/*
| Developed by Muzisecur
| Filename: EFETimeField.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { ExelysFormFieldProps } from '@dirupt/exelys-form'
import { Field, useField, useFormikContext } from 'formik'
import { Autocomplete } from 'formik-mui'
import TextField from '@mui/material/TextField'
import { EFEFieldLayout } from '@/components/common/exelys-form-extended/EFEFieldLayout'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EFEAutoCompleteField: React.FC<ExelysFormFieldProps<'autoCompleteField'>> = ({
	name,
	multiple,
	options,
	label,
	description,
	textFieldProps,
	optional,
	fieldType: _fieldType,
	defaultInitialValue: _defaultInitialValue,
	renderLayout: _renderLayout,
	...props
}) => {
	const [, { error, touched: fieldIsTouched }] = useField(name ?? '')
	const { touched, values } = useFormikContext<{ [key: string]: boolean }>()

	// Render
	//--------------------------------------------------------------------------
	return (
		<EFEFieldLayout label={label} description={description} optional={optional}>
			<Field
				name={name}
				component={Autocomplete}
				multiple={multiple}
				options={typeof options === 'function' ? options(values) : options}
				fullWidth
				renderInput={(params: { id: string }) => {
					const isTouched = touched?.[params.id] || fieldIsTouched
					return (
						<TextField
							{...params}
							{...textFieldProps}
							error={isTouched ? !!error : false}
							helperText={isTouched && error ? error : ''}
						/>
					)
				}}
				{...props}
			/>
		</EFEFieldLayout>
	)
}
